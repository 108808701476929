<template>
	<div class="tabs-container-large">
		<div class="tabs-wrapper">
			<CTabs>
				<CTab
					v-for="(item, index) in components"
					:key="index"
					:title="item.title"
					:active="item.routerTo === $router.currentRoute.name"
					:to="{ name: item.routerTo }"
				>
					<div class="c-main">
						<div class="container-fluid">
							<component
								:is="item.component"
								:is-loading="isLoading"
								:data="getBannersGroupList"
								:meta="list.meta"
								:create-link="item.routerToCreate"
								:edit-link="item.routerToEdit"
								:widget-options="widgetOptions"
								:banner-type="bannerType"
							/>
						</div>
					</div>
				</CTab>
			</CTabs>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import WidgetList from '@/components/WidgetList.vue';
import BannerGroupList from '@/components/BannerGroupList.vue';

import { HOMEPAGE_TABS, HOMEPAGE_COMPONENTS } from '../enums/homepage';
import { BANNER_TYPE } from '../enums/banners';

export default {
	name: 'Homepage',

	components: {
		WidgetList,
		BannerGroupList,
	},
	props: {
		bannerType: {
			type: String,
			default: BANNER_TYPE.hero,
		},
	},
	data() {
		return {
			isLoading: false,
			queryParams: {
				q: this.$route.query.q || null,
				start_at: this.$route.query.start_at || null,
				end_at: this.$route.query.end_at || null,
				widget_id: Number(this.$route.query.widget_id) || null,
				page: Number(this.$route.query.page) || null,
				per_page: Number(this.$route.query.per_page) || null,
				is_visible: (Number(this.$route.query.is_visible) === 0 || Number(this.$route.query.is_visible) === 1)
					? Number(this.$route.query.is_visible)
					: null,
			},
		};
	},
	computed: {
		...mapState('banners', {
			list: 'list',
		}),

		...mapState('widgets', {
			widgetList: 'list',
		}),

		...mapGetters({
			getBannersGroupList: 'banners/getBannersGroupList',
		}),

		components() {
			return HOMEPAGE_TABS.map((tab) => HOMEPAGE_COMPONENTS[tab]);
		},
		widgetType() {
			const widget = {
				hero: 'hero_banner',
				highlight: 'highlight_banner',
				small: 'small_banner',
				// In case text widget type and banner type are same
				text_widget: 'text_widget',
				picture_thumbnail: 'picture_thumbnail',
			};

			return widget[this.bannerType];
		},
		widgetOptions() {
			const allOption = { name: 'All widgets', value: null };
			const list = this.widgetList.data
				.filter((widget) => widget.type === this.widgetType)
				.map((widget) => ({
					name: widget.name,
					value: widget.id,
				}));

			return [allOption, ...list];
		},
	},
	async created() {
		const path = this.$router.currentRoute.path;
		const widgets = this.getWidgets({
			type: this.widgetType,
			per_page: 'all',
		});

		if (path !== 'homepage') {
			const bannerGroup = this.getBannersGroup({
				...this.queryParams,
				type: this.bannerType,
			});

			this.isLoading = true;

			await Promise.all([
				bannerGroup,
				widgets,
			]);

			this.isLoading = false;
		}
	},
	methods: {
		...mapActions({
			getBannersGroup: 'banners/getBannersGroup',
			getWidgets: 'widgets/getWidgets',
		}),
	},
};
</script>
